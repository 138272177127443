<script setup>
import { JCustomButton } from 'media-flow-ui'

import { formatButtonStyle } from "~/helpers/button/button"
import { formatIconStyle } from "~/helpers/icon/icon"

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
  textBeforeButton: {
    type: Boolean,
    default: false,
  },
})

const formattedData = computed(() => {
  return {
    ...props.data,
    style: formatButtonStyle(props.data.style),
    icon: formatIconStyle(props.data.icon),
  }
})
</script>
<template>
  <JCustomButton
    :key="formattedData.id"
    :type="formattedData.props.type"
    :custom-wrapper="formattedData?.props?.linkType === 1 ? 'a' : defineNuxtLink({})"
    :style="formattedData.style"
    :icon="formattedData.icon"
    :text="formattedData.props.text || 'Button'"
    :url="formattedData.props.url"
    :text-before-button="textBeforeButton"
  />
</template>